﻿
module.exports = {
    triglav: {
        settings: {
            general: {
                font_family: "Roboto",
                sizes: {
                    html: "57.5%", //62.5%
                    body: "1.0rem",
                    small: "1.0rem",
                    basic: "1.4rem",
                    header1: "3.0rem",
                    header2: "2.4rem",
                    header3: "1.8rem",
                    header4: "1.4rem",
                    header5: "1.2rem"
                }
            },
            themes: {
                triglav: {
                    primary: "#E74C3C",
                    primary_dark: "#CF4436",
                    primary_light: "#e95c4d",
                    secondary: "#21282E",
                    secondary_light: "#313c45",
                    tertiary: "#2C363F",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "none",
                    logo_decoration: "none",
                    user_decoration: "none",
                    login_effect: "none",
                    footer_background: "none",
                    footer_text: "none"
                },
                blue: {
                    primary: "#46a1c2",
                    primary_dark: "#3d97b8",
                    primary_light: "#55a8c7",
                    secondary: "#444a4f",
                    secondary_light: "#565E65",
                    tertiary: "#626d6e",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "none",
                    logo_decoration: "none",
                    user_decoration: "none",
                    login_effect: "none",
                    footer_background: "none",
                    footer_text: "none"
                },
                cc: {
                    primary: "#85191e",
                    primary_dark: "#77161b",
                    primary_light: "#a61f25",
                    secondary: "#1e1e1e",
                    secondary_light: "#313c45",
                    tertiary: "#2C363F",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "none",
                    logo_decoration: "none",
                    user_decoration: "none",
                    login_effect: "none",
                    footer_background: "none",
                    footer_text: "none"
                },
                green: {
                    primary: "#229c56",
                    primary_dark: "#1f8c4d",
                    primary_light: "#26ac5f",
                    secondary: "#212121",
                    secondary_light: "#313c45",
                    tertiary: "#2C363F",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "none",
                    logo_decoration: "none",
                    user_decoration: "none",
                    login_effect: "none",
                    footer_background: "none",
                    footer_text: "none"
                },
                orange: {
                    primary: "#e67e22",
                    primary_dark: "#dc7419",
                    primary_light: "#e88834",
                    secondary: "#212121",
                    secondary_light: "#313c45",
                    tertiary: "#2C363F",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "none",
                    logo_decoration: "none",
                    user_decoration: "none",
                    login_effect: "none",
                    footer_background: "none",
                    footer_text: "none"
                },
                pink: {
                    primary: "#e08283",
                    primary_dark: "#e67c7d",
                    primary_light: "#da8889",
                    secondary: "#444a4f",
                    secondary_light: "#565E65",
                    tertiary: "#626d6e",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "none",
                    logo_decoration: "none",
                    user_decoration: "none",
                    login_effect: "none",
                    footer_background: "none",
                    footer_text: "none"
                },
                red: {
                    primary: "#d42426",
                    primary_dark: "#be2022",
                    primary_light: "#d8393b",
                    secondary: "#444a4f",
                    secondary_light: "#565E65",
                    tertiary: "#626d6e",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "snow_forest",
                    logo_decoration: "christmas_hat",
                    user_decoration: "christmas_hat",
                    login_effect: "snow",
                    footer_background: "#ffffff",
                    footer_text: "#626d6e"
                },
                purple: {
                    primary: "#d259f6",
                    primary_dark: "#c834f4",
                    primary_light: "#dc7ef8",
                    secondary: "#444a4f",
                    secondary_light: "#565E65",
                    tertiary: "#626d6e",
                    tertiary_light: "#36434E",
                    primary_text: "#626d6e",
                    secondary_text: "#ffffff",
                    color_success: "#1ab394",
                    color_danger: "#ED5565",
                    color_warning: "#f39c12",
                    color_info: "#2980b9",
                    top_decoration: "none",
                    bottom_decoration: "none",
                    logo_decoration: "none",
                    user_decoration: "none",
                    login_effect: "none",
                    footer_background: "none",
                    footer_text: "none"
                }
            }
        }
    }
};
