﻿module.exports = class IziToastModal {

    constructor(dict) {

        let defaultDict = {
            theme: "triglav",
            icon: triglav.toastMessages.definitions.icons.success,
            type: "success",
            title: "Title!",
            message: "Message!",
            position: triglav.toastMessages.definitions.positions.topCenter,
            layout: 2,
            class: triglav.toastMessages.definitions.themes.success,
            timeout: false,
            buttons: this.defaultBtns(),
            func: () => {
                console.log("toast clicked!");
            },
            cancelFunc: () => {
                console.log("toast cancelled!");
            }
        };

        dict = jQuery.extend({}, defaultDict, dict === undefined ? {} : dict);

        this.options = dict;
        
    }

    defaultBtns() {
        return [
            this.button("Ok", true, (toast) => {
                if (this.options.func() !== undefined)
                    this.options.func();
                this.hide(toast);
            }),
            this.button("Cancel", false, (toast) => {
                if (this.options.cancelFunc() !== undefined)
                    this.options.cancelFunc();
                this.hide(toast);
            })
        ];
    }

    button(btnTxt, isPrimary, func) {
        return ['<button class="btn btn-primary btn-' + (isPrimary ? 'cc' : 'white') + '">' + btnTxt + '</button>', func];
    }

    show() {
        iziToast.show(this.options);
    }

    hide(toast) {
        toast = toast || jQuery('.iziToast');
        iziToast.destroy();
    }
};