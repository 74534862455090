﻿let modals = {};
let dynamicmodalFuncs = {};

module.exports = class dynamicmodal {

    static get dynamicmodalFuncs() { return dynamicmodalFuncs; }

    constructor(dict) {

        let defaultDict = {
            id: "modal-" + Math.random().toString().substring(2),
            title: "",
            subtitle: "",
            url: "",
            html: "",
            customClass: "",
            size: "md",
            parent: "body",
            submitText: "Submit",
            cancelText: "Cancel",
            showSubmit: false,
            showCancel: false,
            submitFunc: function () { },
            cancelFunc: function () { },
            closeOnSubmit: true,
            closeOnCancel: true,
            openOnLoad: true,
            onOpen: function () { },
            onLoad: function () { }
        };
        dict = jQuery.extend({}, defaultDict, dict === undefined ? {} : dict);
        let selector = "#" + dict.id;

        if (jQuery(selector)[0] !== undefined) throw "Modal Id is not unique";

        if (dict.url !== undefined && dict.url !== "") {
            jQuery.ajax({
                url: dict.url,
                type: "GET",
                async: false,
                success: function (data) {
                    dict.html = data;
                },
                error: function (err) {
                    dict.html = err;
                }
            });
        }

        dynamicmodalFuncs[dict.id] = {
            submitFunc: function () {
                dict.submitFunc();
                if (dict.closeOnSubmit) jQuery(selector).modal("hide");
            },
            cancelFunc: function () {
                dict.cancelFunc();
                if (dict.closeOnCancel) jQuery(selector).modal("hide");
            }
        };

        let html = '<div class="modal inmodal fade ' + dict.customClass + '" id="' + dict.id + '"  role="dialog">';
        html += '<div class="modal-dialog' + (dict.size !== 'md' ? ' modal-' + dict.size : '') + '" role="document">';
        html += '<div class="modal-content editModal">';
        html += '<div class="modal-header">';
        html += '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
        html += '<h2 class="modal-title" id="myModalLabel">' + dict.title + '</h2>';
        if (dict.subtitle !== "") html += '<small>' + dict.subtitle + '</small>';
        html += '</div>';
        html += '<div class="modal-body"><div class="row no-margin">' + dict.html;
        html += dict.showSubmit || dict.showCancel ? '<div class="row dynamicmodal-footer">' : '';
        if (dict.showCancel) html += '<div class="modal-footer-holder col-md-6 col-sm-6 col-xs-12"><input type="submit" ' +
            'value="' + dict.cancelText + '" class="btn btn-primary btn-grey float-left" onclick="dynamicmodal.dynamicmodalFuncs[\'' + dict.id + '\'].cancelFunc();"/></div>';
        if (dict.showSubmit) html += '<div class="modal-footer-holder col-md-6 col-sm-6 col-xs-12 float-right"><input type="button" ' +
            'value="' + dict.submitText + '" class="btn btn-primary btn-cc float-right btn-submit-modal" onclick="dynamicmodal.dynamicmodalFuncs[\'' + dict.id + '\'].submitFunc();"/></div>';
        html += dict.showSubmit || dict.showCancel ? '</div>' : '';
        html += '</div></div></div></div>';

        jQuery(dict.parent).append(html);
        if (dict.onLoad !== undefined) dict.onLoad();
        jQuery(selector).unbind("shown.bs.modal");
        jQuery(selector).on('shown.bs.modal', function (e) {
            dict.onOpen(dict, e);
        });
        if (dict.openOnLoad) jQuery(selector).modal("show");
        this.options = dict;
        modals[dict.id] = this;

        dynamicmodal.prototype.destroy = function () {
            delete modals[this.options.id];
            delete dynamicmodalFuncs[this.options.id];
            jQuery(".modal-backdrop").remove();
            this.get().remove();
        };

        dynamicmodal.prototype.refresh = function () {
            this.update({ url: this.options.url });
        };
        dynamicmodal.prototype.update = function (dict) {
            let selector = "#" + this.options.id;
            if (this.options.size !== "md")
                jQuery(selector).find(".modal-dialog").removeClass("modal-" + this.options.size);

            if (dict.url !== undefined && dict.url !== "") dict.html = "";
            else dict.url = "";

            dict = jQuery.extend({}, this.options, dict === undefined ? {} : dict);
            jQuery(selector).find(".modal-dialog").addClass("modal-" + dict.size);
            delete dynamicmodalFuncs[this.options.id];
            this.options = dict;
            jQuery(selector).attr("id", dict.id);
            selector = "#" + dict.id;
            dynamicmodalFuncs[dict.id] = {
                submitFunc: function () {
                    dict.submitFunc();
                    if (dict.closeOnSubmit) jQuery("#" + dict.id).modal("hide");
                },
                cancelFunc: function () {
                    dict.cancelFunc();
                    if (dict.closeOnCancel) jQuery("#" + dict.id).modal("hide");
                }
            };

            if (dict.url !== undefined && dict.url !== "") {
                jQuery.ajax({
                    url: dict.url,
                    type: "GET",
                    async: false,
                    success: function (data) {
                        dict.html = data;
                    },
                    error: function (err) {
                        dict.html = err;
                    }
                });
            }

            let modalBody = '<div class="row no-margin">' + dict.html + '</div>';
            jQuery(selector).find(".modal-title").text(dict.title);
            jQuery(selector).find(".modal-body").html(modalBody);
            if (dict.onLoad !== undefined) dict.onLoad();

            if (dict.showSubmit || dict.showCancel) {
                let html = "";
                if (dict.showSubmit) html += '<input type="button" ' +
                    'value="' + dict.submitText + '" class="btn btn-primary btn-cc float-right btn-submit-modal" onclick="dynamicmodal.dynamicmodalFuncs[\'' + dict.id + '\'].submitFunc();"/>';
                if (dict.showCancel) html += '<input type="submit" ' +
                    'value="' + dict.cancelText + '" class="btn btn-primary btn-grey float-left" onclick="dynamicmodal.dynamicmodalFuncs[\'' + dict.id + '\'].cancelFunc();"/>';

                if (jQuery(selector).find(".dynamicmodal-footer").length === 0)
                    jQuery(selector).find(".modal-body .row").append('<div class="form-group row no-margin dynamicmodal-footer"></div>');
                jQuery(selector).find(".dynamicmodal-footer").append(html);
            }
            else jQuery(selector).find(".dynamicmodal-footer").remove();

            jQuery(selector).unbind("shown.bs.modal");
            jQuery(selector).on('shown.bs.modal', function (e) {
                dict.onOpen(dict, e);
            });
            if (dict.openOnLoad) jQuery(selector).modal("show");
        };

        dynamicmodal.prototype.show = function (show) {
            this.get().modal(show ? "show" : "hide");
        };
        dynamicmodal.prototype.hide = function () {
            this.get().modal("hide");
        };
        dynamicmodal.prototype.toggle = function () {
            this.get().modal("toggle");
        };
        dynamicmodal.prototype.get = function () {
            return jQuery("#" + this.options.id);
        };
    }

    static exists(id) {
        return dynamicmodal.get(id) !== undefined;
    }

    static get(id) {
        return modals[id];
    }

    static destroy(id) {
        dynamicmodal.get(id).destroy();
    }

    static refresh(id) {
        dynamicmodal.get(id).refresh();
    }

};
