﻿//css
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../css/bootstrap-xlgrid/bootstrap-xlgrid.css';
import '../../../node_modules/metismenu/dist/metisMenu.min.css';
import '../../../node_modules/bootstrap-tour/build/css/bootstrap-tour.min.css';
import '../../../node_modules/blueimp-gallery/css/blueimp-gallery.min.css';
import '../../sass/main.scss';

//js
import jquery from '../../../node_modules/jquery';
import '../../../node_modules/jquery-ui_1.12/jquery-ui.min';
import '../../../node_modules/jquery-touch-events/src/1.0.9/jquery.mobile-events.min';
import '../../../node_modules/bootstrap/dist/js/bootstrap.min';
import '../../../node_modules/metismenu/dist/metisMenu.min';
import '../../../node_modules/jquery-slimscroll/jquery.slimscroll.min';
import '../../js/script';
import '../../../node_modules/bootstrap-tour/build/js/bootstrap-tour.min';
import '../../non-bower-lib/jquery-idletimer/dist/idle-timer';
import '../../../node_modules/devbridge-autocomplete/dist/jquery.autocomplete.min';
import '../../../node_modules/jquery-validation/dist/jquery.validate.min';
import '../../../node_modules/jquery-validation-unobtrusive/dist/jquery.validate.unobtrusive';
import '../../../node_modules/pwstrength-bootstrap/dist/pwstrength-bootstrap';
import color from '../../non-bower-lib/FlatGenerator/js/flatcolor';
import List from '../../../node_modules/list.js/src/index';

window.jQuery = jquery;
window.$ = jquery;
window.flatColor = color;
window.triglavSettings = require('../../config/config.js');
window.list = List;

import './alerts';