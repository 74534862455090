﻿module.exports = class dynamicswal {

    constructor(swalDict, message, type) {

        if (typeof swalDict === "string") {
            swalDict = { title: swalDict, text: message, type: type };
        }

        var newDict;
        if (type == "success" || type == "error") {
            newDict = jQuery.extend({},
                {
                    confirmButtonColor: '#E74C3C',
                    confirmButtonText: 'OK',
                    confirmButtonClass: 'btn btn-cc w150 m-t-lg',
                    buttonsStyling: false
                },
                swalDict);
        }
        else {
            newDict = jQuery.extend({},
                {
                    confirmButtonColor: '#E74C3C',
                    cancelButtonColor: '#666',
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    confirmButtonClass: 'btn btn-cc w150 float-right m-t-lg',
                    cancelButtonClass: 'btn btn-grey w150 float-left m-t-lg',
                    showCancelButton: true,
                    buttonsStyling: false,
                },
                swalDict);
        }

        dynamicswal.prototype.then = function (functionConfirm, functionCancel) {
            if (functionCancel === undefined)
                functionCancel = function () { };

            dynamicswal.sweetalert.then(functionConfirm, functionCancel);
        };

        try {
            dynamicswal.sweetalert = swal(newDict, function (inputValue) { alert(inputValue); });
        } catch (err) {
            swal(newDict);
        }

    }

};