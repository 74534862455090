﻿//css
import '../../../node_modules/sweetalert2/dist/sweetalert2.min.css';
import '../../../node_modules/izitoast/dist/css/iziToast.min.css';

//js
import swal from '../../../node_modules/sweetalert2/dist/sweetalert2.min';
import toast from '../../../node_modules/izitoast/dist/js/iziToast.min';

window.iziToast = toast;
window.swal = swal;
window.dynamicmodal = require('../../js/dynamic-modal.js');
window.dynamicswal = require('../../js/dynamic-swal.js');
window.IziToastModal = require('../../../js/IziToastModal.js');